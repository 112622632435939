<template>
  <div class="dialog-add-box">
    <el-dialog
      title="添加盒子"
      class="dialog"
      :visible="dialogVisible"
      width="700px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div v-loading="isFetchingBox">
        <el-input clearable placeholder="请输入盒子MAC、端口号进行查询" v-model="keyword" @change="handleSearchChange"></el-input>
        <el-table
          ref="table"
          :data="boxList"
          row-key="boxId"
          highlight-current-row
          :row-class-name="rowClassName"
          @current-change="handleTableChange"
        >
          <el-table-column
            v-for="(item, index) in columns"
            :min-width="item.width"
            :key="index"
            :label="item.label"
            :prop="item.key"
            :sortable="item.sortable"
            :class-name="item.toShop"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <template v-if="item.key === 'status'">
                <status :data="scope.row.status" />
              </template>
              <template v-else-if="item.key === 'boxType'">
                <span>{{ formatLabel(productTypeList, scope.row.boxType) }}</span>
              </template>
              <div v-else>{{ isEmpty(scope.row[item.key]) ? '--' : scope.row[item.key] }}</div>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          class="pagina"
          layout="total, prev, pager, next"
          :currentPage="pageOptions.pageNumber"
          :total="total"
          :pageSize="pageOptions.pageSize"
          @size="handleSizeChange"
          @current="handleCurrentChange"
        ></pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button :disabled="isDisabled" type="primary" :loading="isSubmitting" @click="handleConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { isEmpty } from 'src/utils/method'
import { columns } from './common'
import Pagination from 'src/components/Pagination'
import { solution } from 'src/api/product'
import { mapState } from 'vuex'
import Status from 'src/components/Status'
import { productTypeList } from 'src/utils/dict'
import { formatLabel } from 'src/utils/common'

export default {
  components: {
    Pagination,
    Status,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    hasSolution: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      columns,
      pageOptions: {
        pageNumber: 1,
        pageSize: 5,
      },
      total: 0,
      currentRow: null,
      boxList: [],
      isFetchingBox: true,
      keyword: '',
      isSubmitting: false,
      productTypeList,
    }
  },

  computed: {
    ...mapState({
      step_1: (state) => state.solution.step_1,
    }),
    productId() {
      if (this.hasSolution) {
        return this.$route.query.productId
      } else {
        return this.step_1.productId
      }
    },
    isDisabled() {
      return this.isFetchingBox || this.currentRow === null
    },
  },

  watch: {
    dialogVisible(val) {
      if (val) {
        this.fetchBoxList()
      }
    },
  },

  methods: {
    isEmpty,
    formatLabel,
    async handleConfirm() {
      this.isSubmitting = true
      const { data } = await solution.addBox({
        boxId: this.currentRow.boxId,
        productId: this.productId,
      })
      if (data) {
        this.$emit('confirm', { ...this.currentRow })
      }
      this.isSubmitting = false
    },
    formatQueryData() {
      return {
        keyword: this.keyword.trim(),
        pi: this.pageOptions.pageNumber,
        ps: this.pageOptions.pageSize,
        productId: this.productId,
      }
    },
    async fetchBoxList() {
      this.isFetchingBox = true
      this.currentRow = null
      const params = this.formatQueryData()

      const { status, data } = await solution.getBoxPageForAdd(params)
      if (status === 0) {
        this.boxList = data.list
        this.total = data.total
        this.isFetchingBox = false
      }
    },
    handleSearchChange() {
      this.pageOptions.pageNumber = 1
      this.fetchBoxList()
    },
    rowClassName({ row }) {
      // toAdd: 判断 boxType
      return row.toShop === 0 && row.toAdd ? 'abled' : 'disabled'
    },
    handleTableChange(row) {
      if (row?.toShop === 0) {
        this.$refs.table.setCurrentRow(row)
        this.currentRow = row
      } else {
        this.$refs.table.setCurrentRow()
        this.currentRow = null
      }
    },
    handleClose() {
      this.handleCancel()
    },
    beforeClose() {
      this.pageOptions.pageNumber = 1
      this.currentRow = null
      this.keyword = ''
    },
    handleCancel() {
      this.beforeClose()
      this.$emit('close')
    },
    handleSizeChange(val) {
      this.pageOptions.pageSize = val
      this.fetchBoxList()
    },
    handleCurrentChange(val) {
      this.pageOptions.pageNumber = val
      this.fetchBoxList()
    },
  },
}
</script>

<style lang="less" scoped>
.dialog {
  width: 100%;
  /deep/ .el-table th.el-table__cell.is-leaf {
    background-color: #fff;
  }
}
/deep/ .el-table__row {
  &.disabled {
    background-color: #F2F6FC;
    color: #909399;
    pointer-events: none;
  }
}
/deep/ .el-table--enable-row-hover .el-table__body tr.abled:hover > td.el-table__cell {
  background-color: #c5dafa;
}
/deep/ .el-table__body tr.current-row.abled > td.el-table__cell {
  background-color: #c5dafa;
}
.head {
  padding: 10px 20px;
  font-weight: 600;
}
.pagina {
  text-align: right;
  padding: 24px 0 0;
}
</style>