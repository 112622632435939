<template>
  <div class="pagination-wrap">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 30, 50]"
      :page-size="pageSize"
      :layout="layout"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 100,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper',
    },
  },
  data() {
    return {}
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('size', val)
    },
    handleCurrentChange(val) {
      this.$emit('current', val)
    },
  },
}
</script>

<style scoped lang="less">
.pagination-wrap {
  text-align: right;
  padding: 8px 12px 12px;
}
</style>
