export const columns = [
  {
    label: '盒子MAC',
    key: 'macId',
  },
  {
    label: '端口号',
    key: 'boxPort',
  },
  {
    label: '类型',
    key: 'boxType',
  },
  {
    label: '状态',
    key: 'status',
  },
  {
    label: '门店',
    key: 'shopName',
  },
]
